.content {
  width: 100%;
  min-height: 100vh;
  font-family: Montserrat;
}

.header {
  width: 100%;
  height: 70px;
  background-color: #000000;
}

.logo {
  height: 50px;
  width: auto;
  margin: 10px 40px;
}

.content-body {
  width: calc(100% - 40px);
  padding: 50px 20px;
}

.row {
  width: 100%;
  padding: 0 10px;
}

.p-title {
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
}

.col-100 {
  width: calc(100% - 20px);
  padding: 0 10px;
  display: inline-block;
}

.col-40 {
  width: calc(100% - 20px);
  padding: 10px 10px;
  display: inline-block;
}

.col-45 {
  width: calc(100% - 20px);
  padding: 0 10px;
  display: inline-block;
  vertical-align: top;
}

.col-20 {
  width: 100%;
  padding: 10px 10px;
  display: inline-block;
}

.col-10 {
  width: calc(10% - 20px);
  padding: 0 10px;
  display: inline-block;
}

.col-1-6 {
  width: calc(100% - 20px);
  padding: 0 10px;
  display: inline-block;
  vertical-align: top;
}

.input-text {
  border: 1px solid #3C4858;
  border-radius: 8px;
  font-family: Montserrat;
  width: calc(100% - 40px);
  height: 44px;
  font-size: 12px;
  padding: 0px 20px;
}

.search-icon {
  width: 20px;
  height: auto;
  margin: 0px 0px -5px -15px;
}

.search-button {
  width: calc(100% - 20px);
  padding: 0 10px;
  border: 1px solid #FB8C00;
  border-radius: 8px;
  font-family: Montserrat;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #FB8C00;
  font-size: 12px;
  height: 46px;
  display: block;
}

.search-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.loading {
  width: 100%;
  height: 100vh;
  background-color: #00000033;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-loading {
  width: 100px;
  height: auto;
  margin-top: calc(25% - 20px);
  margin-left: calc(50% - 50px);
  position: relative;
}

.p-subtitle {
  font-family: Montserrat;
  font-weight: 700;
  size: 12px;
}

.right-arrow {
  height: 14px;
}

.status-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #BEBEBE;
  margin-left: calc(25% - 50px);
  margin-right: auto;
  display: block;
}

.status-icon-success {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #4CAF50;
  margin-left: calc(25% - 50px);
  margin-right: auto;
  display: block;
}

.status-icon.after::after {
  content: "____________";
  transform: rotate(90deg);
  color: #D9D9D9;
  font-size: 30px;
  margin-top: -200px;
  margin-left: 20px;
  display: flex;
  position: relative;
  z-index: -100;
}

.status-icon-success.after::after {
  content: "____________";
  transform: rotate(90deg);
  color: #4CAF50;
  font-size: 30px;
  margin-top: -200px;
  margin-left: 20px;
  display: flex;
  position: relative;
  z-index: -100;
}

.img-icon {
  width: 50px;
  height: 50px;
  margin-top: 25px;
  margin-left: 25px;
}

.status-label {
  margin-top: 10px;
  margin-left: calc(30% - 100px);
  margin-right: auto;
  font-size: 11px;
  border-radius: 8px;
  background-color: #A71930;
  width: 200px;
  text-align: center;
  padding: 4px 12px;
  font-weight: 600;
  color: #FFFFFF;
  display: block;
}

.status-label-success {
  margin-top: 10px;
  margin-left: calc(30% - 100px);
  margin-right: auto;
  font-size: 11px;
  border-radius: 8px;
  background-color: #4CAF50;
  width: 200px;
  text-align: center;
  padding: 4px 12px;
  font-weight: 600;
  color: #FFFFFF;
  display: block;
}

.status-fecha {
  text-align: center;
  margin: -15px 0 20px 0;
}

.status-fecha-dia {
  width: 45%;
  padding: 0 0 0 5px;
  margin: 0;
  display: inline-block;
  text-align: right;
}

.status-fecha-mes {
  width: calc(55% - 10px);
  padding: 0 0 0 5px;
  margin: 0;
  display: inline-block;
  text-align: left;
}

.p-status-dia {
  font-weight: 800;
  font-size: 44px;
  line-height: 0px;
}

.p-status-mes {
  font-weight: 500;
  font-size: 10px;
  line-height: 0px;
}

.p-status-sucursal {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}

.card {
  width: 100%;
  box-shadow: 0px 16px 32px -4px #919EAB3D;
  border: 1px solid #919EAB0D;
  border-radius: 17px;
  padding: 30px 0px;
}

.card-row {
  width: calc(100% - 50px);
  padding: 10px 25px;
}

.card-row-stripped {
  width: calc(100% - 50px);
  padding: 10px 25px;
  background-color: #F4F6F8;
}

.card-col-left {
  width: 60%;
  text-align: left;
  display: inline-block;
  font-size: 12px;
  color: #3C4858;
  font-weight: 700;
}

.card-col-right {
  width: 40%;
  text-align: left;
  display: inline-block;
  font-size: 12px;
  color: #3C4858;
  font-weight: 700;
}

.hr-card {
  display: block;
  height: 0px;
  border-top: 0.5px solid #3C48587D;
  padding: 0;
  margin: 0;
}

.p-total {
  font-weight: 700;
  font-size: 14px;
}

@media (min-width: 576px) {
  .content {
    width: 100%;
    min-height: 100vh;
    font-family: Montserrat;
  }
  
  .header {
    width: 100%;
    height: 70px;
    background-color: #000000;
  }
  
  .logo {
    height: 50px;
    width: auto;
    margin: 10px 40px;
  }
  
  .content-body {
    width: calc(100% - 60px);
    padding: 50px 30px;
  }
  
  .row {
    width: 100%;
    padding: 0 10px;
  }
  
  .p-title {
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
  }
  
  .col-100 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-40 {
    width: calc(50% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-45 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .col-20 {
    width: 100%;
    padding: 10px 10px;
    display: inline-block;
  }
  
  .col-10 {
    width: calc(10% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-1-6 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .input-text {
    border: 1px solid #3C4858;
    border-radius: 8px;
    font-family: Montserrat;
    width: calc(100% - 40px);
    height: 44px;
    font-size: 12px;
    padding: 0px 20px;
  }
  
  .search-icon {
    width: 20px;
    height: auto;
    margin: 0px 0px -5px -15px;
  }
  
  .search-button {
    width: calc(100% - 20px);
    padding: 0 10px;
    border: 1px solid #FB8C00;
    border-radius: 8px;
    font-family: Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #FB8C00;
    font-size: 12px;
    height: 46px;
    display: block;
  }
  
  .search-button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .loading {
    width: 100%;
    height: 100vh;
    background-color: #00000033;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo-loading {
    width: 100px;
    height: auto;
    margin-top: calc(25% - 20px);
    margin-left: calc(50% - 50px);
    position: relative;
  }
  
  .p-subtitle {
    font-family: Montserrat;
    font-weight: 700;
    size: 16px;
  }
  
  .right-arrow {
    height: 14px;
  }
  
  .status-icon {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #BEBEBE;
    margin-left: calc(30% - 65px);
    margin-right: auto;
    display: block;
  }
  
  .status-icon-success {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #4CAF50;
    margin-left: calc(30% - 65px);
    margin-right: auto;
    display: block;
  }
  
  .status-icon.after::after {
    content: "____________";
    transform: rotate(90deg);
    color: #D9D9D9;
    font-size: 30px;
    margin-top: -200px;
    margin-left: 20px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .status-icon-success.after::after {
    content: "____________";
    transform: rotate(90deg);
    color: #4CAF50;
    font-size: 30px;
    margin-top: -200px;
    margin-left: 20px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .img-icon {
    width: 70px;
    height: 70px;
    margin-top: 30px;
    margin-left: 30px;
  }
  
  .status-label {
    margin-top: 10px;
    margin-left: calc(30% - 100px);
    margin-right: auto;
    font-size: 12px;
    border-radius: 8px;
    background-color: #A71930;
    width: 200px;
    text-align: center;
    padding: 4px 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: block;
  }
  
  .status-label-success {
    margin-top: 10px;
    margin-left: calc(30% - 100px);
    margin-right: auto;
    font-size: 12px;
    border-radius: 8px;
    background-color: #4CAF50;
    width: 200px;
    text-align: center;
    padding: 4px 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: block;
  }
  
  .status-fecha {
    text-align: center;
    margin: -15px 0 20px 0;
  }
  
  .status-fecha-dia {
    width: 45%;
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: right;
  }
  
  .status-fecha-mes {
    width: calc(55% - 10px);
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: left;
  }
  
  .p-status-dia {
    font-weight: 800;
    font-size: 48px;
    line-height: 0px;
  }
  
  .p-status-mes {
    font-weight: 500;
    font-size: 14px;
    line-height: 0px;
  }
  
  .p-status-sucursal {
    font-weight: 700;
    font-size: 18px;
    line-height: 10px;
  }
  
  .card {
    width: 100%;
    box-shadow: 0px 16px 32px -4px #919EAB3D;
    border: 1px solid #919EAB0D;
    border-radius: 17px;
    padding: 30px 0px;
  }
  
  .card-row {
    width: calc(100% - 50px);
    padding: 10px 25px;
  }
  
  .card-row-stripped {
    width: calc(100% - 50px);
    padding: 10px 25px;
    background-color: #F4F6F8;
  }
  
  .card-col-left {
    width: 60%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .card-col-right {
    width: 40%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .hr-card {
    display: block;
    height: 0px;
    border-top: 0.5px solid #3C48587D;
    padding: 0;
    margin: 0;
  }
  
  .p-total {
    font-weight: 700;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .content {
    width: 100%;
    min-height: 100vh;
    font-family: Montserrat;
  }
  
  .header {
    width: 100%;
    height: 70px;
    background-color: #000000;
  }
  
  .logo {
    height: 50px;
    width: auto;
    margin: 10px 40px;
  }
  
  .content-body {
    width: calc(100% - 100px);
    padding: 50px 50px;
  }
  
  .row {
    width: 100%;
    padding: 0 10px;
  }
  
  .p-title {
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
  }
  
  .col-100 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-40 {
    width: calc(40% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-45 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .col-20 {
    width: calc(20% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-10 {
    width: calc(10% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-1-6 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .input-text {
    border: 1px solid #3C4858;
    border-radius: 8px;
    font-family: Montserrat;
    width: calc(100% - 40px);
    height: 44px;
    font-size: 12px;
    padding: 0px 20px;
  }
  
  .search-icon {
    width: 20px;
    height: auto;
    margin: 0px 0px -5px -15px;
  }
  
  .search-button {
    width: calc(100% - 20px);
    padding: 0 10px;
    border: 1px solid #FB8C00;
    border-radius: 8px;
    font-family: Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #FB8C00;
    font-size: 12px;
    height: 46px;
    display: block;
  }
  
  .search-button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .loading {
    width: 100%;
    height: 100vh;
    background-color: #00000033;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo-loading {
    width: 100px;
    height: auto;
    margin-top: calc(25% - 20px);
    margin-left: calc(50% - 50px);
    position: relative;
  }
  
  .p-subtitle {
    font-family: Montserrat;
    font-weight: 700;
    size: 16px;
  }
  
  .right-arrow {
    height: 14px;
  }
  
  .status-icon {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #BEBEBE;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon-success {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #4CAF50;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon.after::after {
    content: "____________";
    transform: rotate(90deg);
    color: #D9D9D9;
    font-size: 30px;
    margin-top: -200px;
    margin-left: 20px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .status-icon-success.after::after {
    content: "____________";
    transform: rotate(90deg);
    color: #4CAF50;
    font-size: 30px;
    margin-top: -200px;
    margin-left: 20px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .img-icon {
    width: 70px;
    height: 70px;
    margin-top: 30px;
    margin-left: 30px;
  }
  
  .status-label {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    border-radius: 8px;
    background-color: #A71930;
    width: 200px;
    text-align: center;
    padding: 4px 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: block;
  }
  
  .status-label-success {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    border-radius: 8px;
    background-color: #4CAF50;
    width: 200px;
    text-align: center;
    padding: 4px 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: block;
  }
  
  .status-fecha {
    text-align: center;
    margin: -20px 0 20px 0;
  }
  
  .status-fecha-dia {
    width: 60%;
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: right;
  }
  
  .status-fecha-mes {
    width: calc(40% - 10px);
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: left;
  }
  
  .p-status-dia {
    font-weight: 800;
    font-size: 48px;
    line-height: 0px;
  }
  
  .p-status-mes {
    font-weight: 500;
    font-size: 14px;
    line-height: 0px;
  }
  
  .p-status-sucursal {
    font-weight: 700;
    font-size: 18px;
    line-height: 10px;
  }
  
  .card {
    width: 100%;
    box-shadow: 0px 16px 32px -4px #919EAB3D;
    border: 1px solid #919EAB0D;
    border-radius: 17px;
    padding: 30px 0px;
  }
  
  .card-row {
    width: calc(100% - 50px);
    padding: 10px 25px;
  }
  
  .card-row-stripped {
    width: calc(100% - 50px);
    padding: 10px 25px;
    background-color: #F4F6F8;
  }
  
  .card-col-left {
    width: 60%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .card-col-right {
    width: 40%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .hr-card {
    display: block;
    height: 0px;
    border-top: 0.5px solid #3C48587D;
    padding: 0;
    margin: 0;
  }
  
  .p-total {
    font-weight: 700;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .content {
    width: 100%;
    min-height: 100vh;
    font-family: Montserrat;
  }
  
  .header {
    width: 100%;
    height: 70px;
    background-color: #000000;
  }
  
  .logo {
    height: 50px;
    width: auto;
    margin: 10px 40px;
  }
  
  .content-body {
    width: calc(100% - 100px);
    padding: 50px 50px;
  }
  
  .row {
    width: 100%;
    padding: 0 10px;
  }
  
  .p-title {
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
  }
  
  .col-100 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-40 {
    width: calc(40% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-45 {
    width: calc(45% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .col-20 {
    width: calc(20% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-10 {
    width: calc(10% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-1-6 {
    width: calc((100%/6) - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .input-text {
    border: 1px solid #3C4858;
    border-radius: 8px;
    font-family: Montserrat;
    width: calc(100% - 40px);
    height: 44px;
    font-size: 12px;
    padding: 0px 20px;
  }
  
  .search-icon {
    width: 20px;
    height: auto;
    margin: 0px 10px -5px -15px;
  }
  
  .search-button {
    width: calc(100% - 20px);
    padding: 0 10px;
    border: 1px solid #FB8C00;
    border-radius: 8px;
    font-family: Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #FB8C00;
    font-size: 14px;
    height: 46px;
    display: block;
  }
  
  .search-button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .loading {
    width: 100%;
    height: 100vh;
    background-color: #00000033;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo-loading {
    width: 100px;
    height: auto;
    margin-top: calc(25% - 20px);
    margin-left: calc(50% - 50px);
    position: relative;
  }
  
  .p-subtitle {
    font-family: Montserrat;
    font-weight: 700;
    size: 16px;
  }
  
  .right-arrow {
    height: 14px;
  }
  
  .status-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #BEBEBE;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon-success {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #4CAF50;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon.after::after {
    content: "____________";
    transform: rotate(0deg);
    color: #D9D9D9;
    font-size: 22px;
    margin-top: -45px;
    margin-left: -100px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .status-icon-success.after::after {
    content: "____________";
    transform: rotate(0deg);
    color: #4CAF50;
    font-size: 22px;
    margin-top: -45px;
    margin-left: -100px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .img-icon {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-left: 20px;
  }
  
  .status-label {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 7px;
    border-radius: 8px;
    background-color: #A71930;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: block;
  }
  
  .status-label-success {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 7px;
    border-radius: 8px;
    background-color: #4CAF50;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: block;
  }
  
  .status-fecha {
    text-align: center;
    margin: 0;
  }
  
  .status-fecha-dia {
    width: 25%;
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: right;
  }
  
  .status-fecha-mes {
    width: calc(75% - 10px);
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: left;
  }
  
  .p-status-dia {
    font-weight: 800;
    font-size: 28px;
    line-height: 0px;
  }
  
  .p-status-mes {
    font-weight: 500;
    font-size: 9px;
    line-height: 0px;
  }
  
  .p-status-sucursal {
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
  }
  
  .card {
    width: 100%;
    box-shadow: 0px 16px 32px -4px #919EAB3D;
    border: 1px solid #919EAB0D;
    border-radius: 17px;
    padding: 30px 0px;
  }
  
  .card-row {
    width: calc(100% - 50px);
    padding: 10px 25px;
  }
  
  .card-row-stripped {
    width: calc(100% - 50px);
    padding: 10px 25px;
    background-color: #F4F6F8;
  }
  
  .card-col-left {
    width: 60%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .card-col-right {
    width: 40%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .hr-card {
    display: block;
    height: 0px;
    border-top: 0.5px solid #3C48587D;
    padding: 0;
    margin: 0;
  }
  
  .p-total {
    font-weight: 700;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .content {
    width: 100%;
    min-height: 100vh;
    font-family: Montserrat;
  }
  
  .header {
    width: 100%;
    height: 70px;
    background-color: #000000;
  }
  
  .logo {
    height: 50px;
    width: auto;
    margin: 10px 40px;
  }
  
  .content-body {
    width: calc(100% - 100px);
    padding: 50px 50px;
  }
  
  .row {
    width: 100%;
    padding: 0 10px;
  }
  
  .p-title {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  
  .col-100 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-40 {
    width: calc(40% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-45 {
    width: calc(45% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .col-20 {
    width: calc(20% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-10 {
    width: calc(10% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-1-6 {
    width: calc((100%/6) - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .input-text {
    border: 1px solid #3C4858;
    border-radius: 8px;
    font-family: Montserrat;
    width: calc(100% - 40px);
    height: 48px;
    font-size: 14px;
    padding: 0px 20px;
  }
  
  .search-icon {
    width: 24px;
    height: auto;
    margin: 0px 10px -5px -20px;
  }
  
  .search-button {
    width: calc(100% - 20px);
    padding: 0 10px;
    border: 1px solid #FB8C00;
    border-radius: 8px;
    font-family: Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #FB8C00;
    font-size: 16px;
    height: 50px;
    display: block;
  }
  
  .search-button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .loading {
    width: 100%;
    height: 100vh;
    background-color: #00000033;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo-loading {
    width: 100px;
    height: auto;
    margin-top: calc(25% - 20px);
    margin-left: calc(50% - 50px);
    position: relative;
  }
  
  .p-subtitle {
    font-family: Montserrat;
    font-weight: 700;
    size: 18px;
  }
  
  .right-arrow {
    height: 16px;
  }
  
  .status-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #BEBEBE;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon-success {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #4CAF50;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon.after::after {
    content: "____________";
    transform: rotate(0deg);
    color: #D9D9D9;
    font-size: 30px;
    margin-top: -55px;
    margin-left: -100px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .status-icon-success.after::after {
    content: "____________";
    transform: rotate(0deg);
    color: #4CAF50;
    font-size: 30px;
    margin-top: -55px;
    margin-left: -100px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .img-icon {
    width: 50px;
    height: 50px;
    margin-top: 25px;
    margin-left: 25px;
  }
  
  .status-label {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 9px;
    border-radius: 8px;
    background-color: #A71930;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 700;
    color: #FFFFFF;
    display: block;
  }
  
  .status-label-success {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 9px;
    border-radius: 8px;
    background-color: #4CAF50;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 700;
    color: #FFFFFF;
    display: block;
  }
  
  .status-fecha {
    text-align: center;
    margin: 0;
  }
  
  .status-fecha-dia {
    width: 30%;
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: right;
  }
  
  .status-fecha-mes {
    width: calc(70% - 10px);
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: left;
  }
  
  .p-status-dia {
    font-weight: 800;
    font-size: 32px;
    line-height: 0px;
  }
  
  .p-status-mes {
    font-weight: 500;
    font-size: 10px;
    line-height: 0px;
  }
  
  .p-status-sucursal {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
  }
  
  .card {
    width: 100%;
    box-shadow: 0px 16px 32px -4px #919EAB3D;
    border: 1px solid #919EAB0D;
    border-radius: 17px;
    padding: 30px 0px;
  }
  
  .card-row {
    width: calc(100% - 50px);
    padding: 10px 25px;
  }
  
  .card-row-stripped {
    width: calc(100% - 50px);
    padding: 10px 25px;
    background-color: #F4F6F8;
  }
  
  .card-col-left {
    width: 60%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .card-col-right {
    width: 40%;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .hr-card {
    display: block;
    height: 0px;
    border-top: 0.5px solid #3C48587D;
    padding: 0;
    margin: 0;
  }
  
  .p-total {
    font-weight: 700;
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  .content {
    width: 100%;
    min-height: 100vh;
    font-family: Montserrat;
  }
  
  .header {
    width: 100%;
    height: 70px;
    background-color: #000000;
  }
  
  .logo {
    height: 50px;
    width: auto;
    margin: 10px 40px;
  }
  
  .content-body {
    width: calc(100% - 200px);
    padding: 50px 100px;
  }
  
  .row {
    width: 100%;
    padding: 0 10px;
  }
  
  .p-title {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  
  .col-100 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-40 {
    width: calc(40% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-45 {
    width: calc(45% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .col-20 {
    width: calc(20% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-10 {
    width: calc(10% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-1-6 {
    width: calc((100%/6) - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .input-text {
    border: 1px solid #3C4858;
    border-radius: 8px;
    font-family: Montserrat;
    width: calc(100% - 40px);
    height: 48px;
    font-size: 14px;
    padding: 0px 20px;
  }
  
  .search-icon {
    width: 24px;
    height: auto;
    margin: 0px 10px -5px -20px;
  }
  
  .search-button {
    width: calc(100% - 20px);
    padding: 0 10px;
    border: 1px solid #FB8C00;
    border-radius: 8px;
    font-family: Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #FB8C00;
    font-size: 16px;
    height: 50px;
    display: block;
  }
  
  .search-button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .loading {
    width: 100%;
    height: 100vh;
    background-color: #00000033;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo-loading {
    width: 100px;
    height: auto;
    margin-top: calc(25% - 20px);
    margin-left: calc(50% - 50px);
    position: relative;
  }
  
  .p-subtitle {
    font-family: Montserrat;
    font-weight: 700;
    size: 18px;
  }
  
  .right-arrow {
    height: 16px;
  }
  
  .status-icon {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #BEBEBE;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon-success {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #4CAF50;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon.after::after {
    content: "____________";
    transform: rotate(0deg);
    color: #D9D9D9;
    font-size: 30px;
    margin-top: -65px;
    margin-left: -100px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .status-icon-success.after::after {
    content: "____________";
    transform: rotate(0deg);
    color: #4CAF50;
    font-size: 30px;
    margin-top: -65px;
    margin-left: -100px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .img-icon {
    width: 70px;
    height: 70px;
    margin-top: 30px;
    margin-left: 30px;
  }
  
  .status-label {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 10px;
    border-radius: 8px;
    background-color: #A71930;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 700;
    color: #FFFFFF;
    display: block;
  }
  
  .status-label-success {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 10px;
    border-radius: 8px;
    background-color: #4CAF50;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 700;
    color: #FFFFFF;
    display: block;
  }
  
  .status-fecha {
    text-align: center;
    margin: 0;
  }
  
  .status-fecha-dia {
    width: 30%;
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: right;
  }
  
  .status-fecha-mes {
    width: calc(70% - 10px);
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: left;
  }
  
  .p-status-dia {
    font-weight: 800;
    font-size: 36px;
    line-height: 0px;
  }
  
  .p-status-mes {
    font-weight: 500;
    font-size: 11px;
    line-height: 0px;
  }
  
  .p-status-sucursal {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }
  
  .card {
    width: 100%;
    box-shadow: 0px 16px 32px -4px #919EAB3D;
    border: 1px solid #919EAB0D;
    border-radius: 17px;
    padding: 30px 0px;
  }
  
  .card-row {
    width: calc(100% - 60px);
    padding: 10px 30px;
  }
  
  .card-row-stripped {
    width: calc(100% - 60px);
    padding: 10px 30px;
    background-color: #F4F6F8;
  }
  
  .card-col-left {
    width: 60%;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .card-col-right {
    width: 40%;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .hr-card {
    display: block;
    height: 0px;
    border-top: 0.5px solid #3C48587D;
    padding: 0;
    margin: 0;
  }
  
  .p-total {
    font-weight: 700;
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  .content {
    width: 100%;
    min-height: 100vh;
    font-family: Montserrat;
  }
  
  .header {
    width: 100%;
    height: 70px;
    background-color: #000000;
  }
  
  .logo {
    height: 50px;
    width: auto;
    margin: 10px 40px;
  }
  
  .content-body {
    width: calc(100% - 200px);
    padding: 50px 100px;
  }
  
  .row {
    width: 100%;
    padding: 0 10px;
  }
  
  .p-title {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  
  .col-100 {
    width: calc(100% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-40 {
    width: calc(40% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-45 {
    width: calc(45% - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .col-20 {
    width: calc(20% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-10 {
    width: calc(10% - 20px);
    padding: 0 10px;
    display: inline-block;
  }
  
  .col-1-6 {
    width: calc((100%/6) - 20px);
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  .input-text {
    border: 1px solid #3C4858;
    border-radius: 8px;
    font-family: Montserrat;
    width: calc(100% - 40px);
    height: 48px;
    font-size: 14px;
    padding: 0px 20px;
  }
  
  .search-icon {
    width: 24px;
    height: auto;
    margin: 0px 10px -5px -20px;
  }
  
  .search-button {
    width: calc(100% - 20px);
    padding: 0 10px;
    border: 1px solid #FB8C00;
    border-radius: 8px;
    font-family: Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #FB8C00;
    font-size: 16px;
    height: 50px;
    display: block;
  }
  
  .search-button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .loading {
    width: 100%;
    height: 100vh;
    background-color: #00000033;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo-loading {
    width: 100px;
    height: auto;
    margin-top: calc(25% - 20px);
    margin-left: calc(50% - 50px);
    position: relative;
  }
  
  .p-subtitle {
    font-family: Montserrat;
    font-weight: 700;
    size: 18px;
  }
  
  .right-arrow {
    height: 16px;
  }
  
  .status-icon {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #BEBEBE;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon-success {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #4CAF50;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .status-icon.after::after {
    content: "______________";
    transform: rotate(0deg);
    color: #D9D9D9;
    font-size: 40px;
    margin-top: -75px;
    margin-left: -170px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .status-icon-success.after::after {
    content: "______________";
    transform: rotate(0deg);
    color: #4CAF50;
    font-size: 40px;
    margin-top: -75px;
    margin-left: -170px;
    display: flex;
    position: relative;
    z-index: -100;
  }
  
  .img-icon {
    width: 70px;
    height: 70px;
    margin-top: 30px;
    margin-left: 30px;
  }
  
  .status-label {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    border-radius: 8px;
    background-color: #A71930;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 700;
    color: #FFFFFF;
    display: block;
  }
  
  .status-label-success {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    border-radius: 8px;
    background-color: #4CAF50;
    width: fit-content;
    text-align: center;
    padding: 4px 12px;
    font-weight: 700;
    color: #FFFFFF;
    display: block;
  }
  
  .status-fecha {
    text-align: center;
    margin: 0;
  }
  
  .status-fecha-dia {
    width: 30%;
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: right;
  }
  
  .status-fecha-mes {
    width: calc(70% - 10px);
    padding: 0 0 0 5px;
    margin: 0;
    display: inline-block;
    text-align: left;
  }
  
  .p-status-dia {
    font-weight: 800;
    font-size: 44px;
    line-height: 0px;
  }
  
  .p-status-mes {
    font-weight: 500;
    font-size: 14px;
    line-height: 0px;
  }
  
  .p-status-sucursal {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  
  .card {
    width: 100%;
    box-shadow: 0px 16px 32px -4px #919EAB3D;
    border: 1px solid #919EAB0D;
    border-radius: 17px;
    padding: 30px 0px;
  }
  
  .card-row {
    width: calc(100% - 60px);
    padding: 10px 30px;
  }
  
  .card-row-stripped {
    width: calc(100% - 60px);
    padding: 10px 30px;
    background-color: #F4F6F8;
  }
  
  .card-col-left {
    width: 60%;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .card-col-right {
    width: 40%;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    color: #3C4858;
    font-weight: 700;
  }
  
  .hr-card {
    display: block;
    height: 0px;
    border-top: 0.5px solid #3C48587D;
    padding: 0;
    margin: 0;
  }
  
  .p-total {
    font-weight: 700;
    font-size: 16px;
  }
}